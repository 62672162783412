<template>
  <v-container id="BollettaInterattivaFooter5Dt" class="pa-7">
    <v-row class="ContentBollettaInterattivaFooter5Dt">
     <div class="tw-100 tw-mgt1_1">
      <p class="tw-testo tw-testoline">SERVIZIO CLIENTI</p>
        <a href="mailto:clienti@unoenergy.it" target="_blank" class="tw-testo">
          <span>clienti@unoenergy.it</span>
        </a>
      <p class="tw-testo tw-testoline tw-mgt1">RECLAMI</p>
      <a href="mailto:reclami@unoenergy.it" target="_blank" class="tw-testo"><span>reclami@unoenergy.it</span></a>
      </div>
    </v-row>
  </v-container>
</template>
<script>

export default {

  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        getHolderAddress: function ()
        {
         return (this.documentData.holder_ind_riga2 || this.documentData.holder_ind_riga3 || this.documentData.holder_provincia) ? 
                (this.documentData.holder_ind_riga2 ? this.documentData.holder_ind_riga2 : '') + (this.documentData.holder_ind_riga3 ? ' ' + this.documentData.holder_ind_riga3 : '') + (this.documentData.holder_cap ? ' - ' + this.documentData.holder_cap : '') : + (this.documentData.holder_provincia ? ' ' + this.documentData.holder_provincia : '') ;
        },
  }

}
</script>

<style>

</style>